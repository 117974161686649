import React from "react";

const Desativado = () => {

    return (
        <>
            <main></main>
        </>
    )

}

export default Desativado;